import {
  collection,
  getDocs,
  getFirestore,
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  getDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { uuidv4 } from "lib0/random";
import * as Y from "yjs";
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import Swal from "sweetalert2";
import { englishConsoleStarterCode, englishGraphicsStarterCode, englishKarelStarterCode, spanishConsoleStarterCode, spanishGraphicsStarterCode, spanishKarelStarterCode } from "./starterCode";
import { isSpanishCourse } from "hooks/router/useUrlParams";
import { getFunctions, httpsCallable } from "firebase/functions";
import { runUnitTestsAndReportResults } from "ide/UnitTest/runUnitTestsAndReportResults";



function getStarterCode(projectType) {
  // HACK: right now we decide if you are spanish based off course code

  const isSpanish = isSpanishCourse()
  if (projectType === "karel") {
    return isSpanish ? spanishKarelStarterCode : englishKarelStarterCode;
  } else if (projectType === "graphics") {
    return isSpanish ? spanishGraphicsStarterCode : englishGraphicsStarterCode;
  } else {
    return isSpanish ? spanishConsoleStarterCode : englishConsoleStarterCode;
  }
}

export const getProjectFilesCode = async (projectId) => {
  const db = getFirestore();
  const collectionRef = collection(db, `projects/${projectId}/code`);
  const response = await getDocs(collectionRef);

  const responseData = {};
  response.forEach((doc) => {
    responseData[doc.id] = {
      content: doc.data().content,
    };
  });

  return responseData;
};

export const updateLastOpenedFile = async (projectId, file) => {
  if(!file) {
    return;
  }
  const db = getFirestore();
  const projectRef = doc(db, "projects", projectId);
  try {
    await updateDoc(projectRef, {
      lastOpenedFile: file,
    });
  } catch (error) {
    console.log("Error updating last opened file", error);
  }
};

export const updateProjectTitle = async (projectId, projectTitle) => {
  const db = getFirestore();
  const projectRef = doc(db, "projects", projectId);

  await updateDoc(projectRef, {
    title: projectTitle,
  });
};

export const createCodeFile = async (docRef, codeStr) => {
  
  const ydoc = new Y.Doc();
  const yText = ydoc.getText("ace");
  yText.insert(0, codeStr);
  let docArray = Y.encodeStateAsUpdateV2(ydoc);
  const docBlob = firebase.firestore.Blob.fromUint8Array(docArray);

  await setDoc(docRef, {
    content: codeStr,
    ydoc: docBlob,
    lastEdit: new Date(),
    size: docArray.length,
    updatingClient: "default"
  });
};

export const createConsoleStarterCodeFiles = async (
  projectName,
  projectId
) => {
  // init
  const db = getFirestore();
  const projectRef = doc(db, "projects", projectId);

  // Setup the file structure
  var starterFiles = [
    {
      id: uuidv4(),
      type: "file",
      name: "main.py",
      format: "doc",
    },
  ];

  await updateDoc(projectRef, {
    files: [
      {
        type: "folder",
        name: projectName,
        files: starterFiles,
      },
    ],
  });

  // Based on file structure, create actual yjs files
  for (let i = 0; i < starterFiles.length; i++) {
    const code = getStarterCode('console');
    const starterCodeFileRef = doc(
      db,
      `/projects/${projectId}/code`,
      starterFiles[i].id
    );
    await createCodeFile(starterCodeFileRef, code);
  }

  return starterFiles;
};

export const createGraphicsStarterCodeFiles = async (
  projectName,
  projectId
) => {
  // init
  const db = getFirestore();
  const projectRef = doc(db, "projects", projectId);

  // Setup the file structure
  var starterFiles = [
    {
      id: uuidv4(),
      type: "file",
      name: "main.py",
      format: "doc",
    },
  ];

  await updateDoc(projectRef, {
    files: [
      {
        type: "folder",
        name: projectName,
        files: starterFiles,
      },
    ],
  });

  // Based on file structure, create actual yjs files
  for (let i = 0; i < starterFiles.length; i++) {
    const code = getStarterCode('graphics');
    const starterCodeFileRef = doc(
      db,
      `/projects/${projectId}/code`,
      starterFiles[i].id
    );
    await createCodeFile(starterCodeFileRef, code);
  }

  return starterFiles;
};

export const createKarelStarterCodeFiles = async (
  projectName,
  projectId
) => {
  // init
  const db = getFirestore();
  const projectRef = doc(db, "projects", projectId);

  // Setup the file structure
  var starterFiles = [
    {
      id: uuidv4(),
      type: "file",
      name: "main.py",
      format: "doc",
    },
  ];

  await updateDoc(projectRef, {
    files: [
      {
        type: "folder",
        name: projectName,
        files: starterFiles,
      },
    ],
  });

  // Based on file structure, create actual yjs files
  for (let i = 0; i < starterFiles.length; i++) {
    const code = getStarterCode('karel');
    const starterCodeFileRef = doc(
      db,
      `/projects/${projectId}/code`,
      starterFiles[i].id
    );
    await createCodeFile(starterCodeFileRef, code);
  }

  return starterFiles;
};

export const createAssnStarterCodeFiles = async (assnData, projectId) => {
  // init
  const db = getFirestore();
  const storage= getStorage();
  const projectRef = doc(db, "projects", projectId);

  // TODO: Fix this! I am so sorry that I need to do this


  // Setup the file structure
  var starterFiles = [];
  var codeFiles = []
  for (const file in assnData?.starterCode) {
    const codeFile = {
      id: uuidv4(),
      type: "file",
      name: file,
      starterFile: true,
      format: "doc",
    }
    starterFiles.push(codeFile);
    codeFiles.push(codeFile);
  }

  // There may be some images in the assignment
  for (const file in assnData?.images) {
    const imagePath = assnData.images[file];
    const imageRef = ref(storage, imagePath);
    const imageUrl = await getDownloadURL(imageRef);
    starterFiles.push({
      id: uuidv4(),
      type: "file",
      format:"image",
      url: imageUrl,
      name: file,
    });
  }

  let projectUpdates = {
    files: [
      {
        type: "folder",
        name: assnData?.metaData.title,
        files: starterFiles,
      },
    ],
  };
  if (starterFiles.length > 0) {
    projectUpdates.lastOpenedFile = {
      id: starterFiles[0].id,
      name: starterFiles[0].name,
    };
    for(let i = 0; i < starterFiles.length; i++) {
      if(starterFiles[i] && starterFiles[i].name==="main.py") {
        projectUpdates.lastOpenedFile = {
          id: starterFiles[i].id,
          name: starterFiles[i].name,
        };
        break;
      }
    }

  }
  await setDoc(projectRef, projectUpdates, { merge: true });

  // Based on file structure, create actual yjs files
  for (let i = 0; i < codeFiles.length; i++) {
    const fileName = codeFiles[i].name;
    const code = assnData.starterCode[fileName];
    const starterCodeFileRef = doc(
      db,
      `/projects/${projectId}/code`,
      codeFiles[i].id
    );
    // const oldFile = await getDoc(starterCodeFileRef);
    
    await createCodeFile(starterCodeFileRef, code);
  }

  return starterFiles;
};

// flattens file structure into a list of image names
export const getAllImages = (fileStructure) => {
  let fileNames = [];
  for (let i = 0; i < fileStructure.length; i++) {
    if (fileStructure[i].type === "file" && fileStructure[i].url) {
      fileNames[fileStructure[i].name] = fileStructure[i].url;
    } else if (fileStructure[i].type === "folder") {
      fileNames = {
        ...fileNames,
        ...getAllImages(fileStructure[i].files),
      };
    }
  }

  return fileNames;
};

// recursively removes a prop from every single layer
export const removeProps = (obj, keys) => {
  if (Array.isArray(obj)) {
    obj.forEach(function (item) {
      removeProps(item, keys);
    });
  } else if (typeof obj === "object" && obj != null) {
    Object.getOwnPropertyNames(obj).forEach(function (key) {
      if (keys.indexOf(key) !== -1) delete obj[key];
      else removeProps(obj[key], keys);
    });
  }
};

// flattens file structure to an array of file names
export const getAllFileNames = (fileStructure) => {
  let fileNames = [];
  for (let i = 0; i < fileStructure.length; i++) {
    if (fileStructure[i].type === "file") {
      fileNames.push({
        name: fileStructure[i].name,
        id: fileStructure[i].id,
      });
    }

    if (fileStructure[i].type === "folder") {
      fileNames = [...fileNames, ...getAllFileNames(fileStructure[i].files)];
    }
  }

  return fileNames;
};

export const getAllFileNamesWithoutImages = (fileStructure) => {
  let fileNames = [];
  for (let i = 0; i < fileStructure.length; i++) {
    if (
      fileStructure[i].type === "file" &&
      fileStructure[i].format != "image"
    ) {
      fileNames.push({
        name: fileStructure[i].name,
        id: fileStructure[i].id,
      });
    }

    if (fileStructure[i].type === "folder") {
      fileNames = [
        ...fileNames,
        ...getAllFileNamesWithoutImages(fileStructure[i].files),
      ];
    }
  }

  return fileNames;
};

export const setCodeForStyleFeedback = async (courseId, userId, projectId, mainFile) => {
  try {
    const db = getFirestore();
    // Make a new project for the style feedback with this project id or reference existing one
    const projectRef = doc(db, `styleFeedback/${courseId}/projects/${projectId}`);
  
    // Make a new collection for the style feedback requests for this project
    const subCollectionRef = collection(projectRef, "styleFeedbackRequests");

    // Add a doc for this time they hit style feedback 
    const docData = {code: mainFile, userId: userId, projectId: projectId}
    return addDoc(subCollectionRef, docData).then((newDoc) => {
      return newDoc.id;
    });
  }
  catch (error) {
    console.log(error);
  }
}

export const getCodeForStyleFeedback = async (courseId, projectId, feedbackId) => {
  try {
    const db = getFirestore();
    const docRef = doc(db, `styleFeedback/${courseId}/projects/${projectId}/styleFeedbackRequests`, feedbackId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().code) {
        return docSnap.data().code;
      }
    } else {
      console.log("No such document!");
    }
  }
  catch (error) {
    console.log(error);
  }
}
  

export const publishProject = async (
  projectId,
  mainFile,
  fileStructure,
  filesCode,
  courseId,
  user,
  assnData,
  showTerminal,
  defaultWorld,
  title,
) => {
  try {
    const db = getFirestore();

    // create a new publish project document with the same project id
    const projectRef = doc(
      db,
      `/published/${courseId}/studentPublished/`,
      projectId
    );

    // Delete the previous code entries for this project
    const collectionRef = collection(
      db,
      `published/${courseId}/studentPublished/${projectId}/code`
    );
    const response = await getDocs(collectionRef);
    for (var i = 0; i < response.size; i++) {
      const toDeleteDocRef = response.docs[i].ref
      await deleteDoc(toDeleteDocRef);
    }

    // update the code
    for (const fileId in filesCode) {
      const projectCodeDocRef = doc(
        db,
        `/published/${courseId}/studentPublished/${projectId}/code/`,
        fileId
      );

      await setDoc(projectCodeDocRef, {
        content: filesCode[fileId]?.content,
      });
    }

    // update the metadata
    removeProps(fileStructure, ["parentNode"]);
    // Update the data for this project (note that this is different than the code)
    await setDoc(projectRef, {
      files: fileStructure,
      date: new Date(),
      mainFile,
      user,
      numLikes: 0, // Every time a project is published, it starts with zero likes,
      assnData,
      showTerminal,
      editors:[user.id],
      karelWorld: defaultWorld,
      title: title,
    });

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export function checkIsProjectKarel(projectData,assnData) {
  return checkProjectIsType(projectData, assnData, "karel");
}

export function checkIsProjectConsole(projectData, assnData) {
  return checkProjectIsType(projectData, assnData, "console");
}

export function checkIsProjectGraphics(projectData, assnData) {
  return checkProjectIsType(projectData,assnData, "graphics");
}

export function checkIsProjectRandom(assnData) {
  if (assnData) {
    const metaData = assnData.metaData;
    return metaData.isRandom;
  }

  return false;
}

export function checkProjectIsType(projectData, assnData, goalType) {
  const projectType = getProjectType(projectData, assnData);
  return projectType === goalType;
}

export function getProjectType(projectData, assnData) {
  if (assnData) {

    const metaData = assnData.metaData;
    return metaData.type;
  }
  if(projectData) {
    return projectData.type
  }
  return '';
}

export function isCreativeProject(projectData) {
  const assnId = projectData?.assnId;
  return assnId === undefined;
}

export async function saveUserWorldState(worldState, projectId) {
  if(projectId && worldState) {
    if(worldState["paint"]) {
      worldState["paint"] = {}
    }
    const db = getFirestore();
    const docRef = doc(db, `projects/${projectId}`);
    updateDoc(docRef, {
      userKarel: worldState
    })
  }

}



export  const focusRelevantPane = (terminal, isKarel, isConsole, isGraphics) => {
  if (isConsole || isKarel) {
    terminal.focusTerm();
  } else if (isGraphics) {
    terminal.blur()
  }
}


export const onAssignmentSubmit = async (
  courseId, 
  assnId, 
  userId, 
  projectId, 
  wasPreviouslySubmitted, 
  setIsSubmitted, 
  courseCanvasAuthLink, 
  courseAssnLink="",
  navigate,
  ideContext
) => {
  const db = getFirestore();
  const functions = getFunctions();
  const submitCanvasAssn = httpsCallable(functions, 'submitCanvasAssn');
  const submissionDocRef = doc(db, `submissions/${courseId}/assignments/${assnId}/users/${userId}`);

  // TODO: decide if we should require a confirmation for all submissions
  const isCS105 = courseId === "cs105f24";

  if(isCS105) {

    const submitMessage = 'You are about to submit your assignment. You will not be able to make changes after this unless you unsubmit, and the time of you\'re submission will be marked.'
    const unsubmitMessage = 'You are about to unsubmit your assignment. You will be able to make changes after this, but you\'re project won\'t be marked as submitted.'
    const { value: confirm } = await Swal.fire({
      title: 'Are you sure?',
      text: wasPreviouslySubmitted ? unsubmitMessage : submitMessage,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: wasPreviouslySubmitted ? 'Unsubmit' : 'Submit',
      cancelButtonText: 'Cancel'
    });

    if (!confirm) {
      return;
    }
  }

  if(!wasPreviouslySubmitted) {

    try {
      let data;
      let result;

      await runUnitTestsAndReportResults({
        ideContext,
        navigate,
        userId,
        courseId,
        onwardsUrl: "",
        silent: true
      })

      if(!!courseCanvasAuthLink) {

        result = await submitCanvasAssn({
          courseId,
          projectId,
          assnId
        });

      } 

      data =  result && result.data ? result.data : null;
      // If the submission was successful, or course does not use canvas, update the submission doc

      if(data && data.success || !(!!courseCanvasAuthLink)) {
        await setDoc(submissionDocRef, {
          lastSubmissionRef: projectId,
          isSubmitted: true,
          status: "submitted",
          timestamp: serverTimestamp()
        });


        const { genericUrl } = !!data ? data : {
          genericUrl: courseAssnLink
        };

        const assnLink = `https://codeinplace.stanford.edu/${courseId}/ide/p/${projectId}`
        const atCanvasMessage = !!courseAssnLink ? ` <a href="${genericUrl}" target="_blank">here</a>` : '';

        const htmlMessage = !!data ? 'Assignment submitted to Canvas successfully!' + (genericUrl ? ` You can view your submission <a href="${genericUrl}" target="_blank">here</a>` : '') : `Assignment submitted successfully on Code in Place! Remember to also submit the link to your project to Canvas ${atCanvasMessage}. 
        <br/>
        <input value=${assnLink} />
        <p>Copy and submit your unique project link to Canvas.</p>
        `;

        Swal.fire({
          icon: 'success',
          title: 'Success',
          html: htmlMessage,
          // showConfirmButton: true,
          // preConfirm: () => {
          //   navigator.clipboard.writeText(assnLink);
          // },
          // confirmButtonText: 'Copy Link'
        });
      }
      setIsSubmitted(true);


    } catch (error) {

      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Please try again.',
      });
    }
  } else {
    await setDoc(submissionDocRef, {
      lastSubmissionRef: projectId,
      isSubmitted: false,
      status: "unsubmitted",
      timestamp: serverTimestamp()
    });
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Assignment unsubmitted. In order to unsubmit to Canvas, you must do so in the Canvas platform',
    });
    setIsSubmitted(false);

  }

  return
}


export const getNextSubmissionId = (assnData, thisProjectId = '') => {
  if (!assnData?.adminSubmissionData) {
    return null;
  }
  // get all students who have submitted but not been graded
  // sort by submission time (timestamp field) -- earliest first
  const needsGrading = Object.keys(assnData.adminSubmissionData)
    .filter(
      userId => assnData.adminSubmissionData[userId].status === 'submitted',
    )
    .filter(userId => !('score' in assnData.adminSubmissionData[userId])) // postGrade firebase function will set the score field when grade is posted
    .filter(
      userId =>
        assnData.adminSubmissionData[userId].lastSubmissionRef !==
        thisProjectId,
    )
    .sort(
      (a, b) =>
        assnData.adminSubmissionData[a].timestamp -
        assnData.adminSubmissionData[b].timestamp,
    );
  if (needsGrading.length === 0) {
    return null;
  }
  const nextStudent = needsGrading[0];
  return assnData.adminSubmissionData[nextStudent].lastSubmissionRef;
};