import {
  FaCalendar,
  FaCode,
  FaCheck,
  FaCommentDots,
  FaFile,
  FaTimes,
  FaClock,
  FaEdit,
  FaClipboardCheck,
  FaEnvelope,
} from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { ProfileContext } from "contexts/ProfileContext";
import { Role } from "types/role";
import { CourseContext } from "contexts/CourseContext";
import { useContext, useEffect, useState } from "react";
import { PartialLoading } from "components/loading/Loading";
import { useUserId } from "hooks/user/useUserId";
import { useWindowSize } from "react-use-size";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import firebase from "firebase/compat/app";
import Swal from "sweetalert2";
import styled from "styled-components";
import { Banner } from "./components/Banner";
import {
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { getUserDocumentRef } from "../../firebase/models";
import {
  getSectionTimeStr,
  getLocalUTCTimezone,
  formateDateWithSuffix,
  getTimeUntil,
} from "components/timezones/timezoneHelper";
import { TipTap } from "components/richTextEditor/TipTap/TipTap";
import { DiscussionButtonBar } from "components/richTextEditor/TipTap/buttonbars/DiscussionButtonBar";
import { getApp } from "firebase/app";
import { Link } from "react-router-dom";
import { isMinimumRole } from "contexts/profile_util";
import { useSectionWeekIdePath } from "./SectionUtils";
import { useCourseId } from "hooks/router/useUrlParams";
import { RSVP } from "./components/RSVP";
import { JoinSectionZoomButton, CustomizedZoomButton } from "./components/JoinSectionZoomButton";
import { SectionMember } from "./components/SectionMember";
import Gate from "../../contexts/Gate";
import { JoinAlternativeSectionButton } from "./components/JoinAlternativeSectionButton";
import { useServerTime } from "contexts/TimeContext";
import { KindDate } from "components/reusableButtons/KindDate";

export const SectionPage = ({}) => {
  const courseId = useCourseId();
  const sectionId = useParams().sectionId;
  const db = getFirestore(getApp());
  const { firstSectionTimestamp, currCourseWeek, getNextSectionDate } = useContext(CourseContext)
  const sectionRef = doc(db, `sections/${sectionId}`);
  const [sectionData, sectionDataIsLoading, sectionDataError] =
    useDocumentData(sectionRef);
  // const [firstSectionDateTime, setFirstSectionDateTime] = useState(null);
  // const [nextSectionDate, setNextSectionDate] = useState(null);
  const [sectionMalformed, setSectionMalformed] = useState(false);
  const firstSectionDateTime = new Date(firstSectionTimestamp)
  const timeIndex = sectionData?.timeIndex;
  if(timeIndex !== "none" && timeIndex !== undefined) {
    const updatedFirstSectionDateTime = new Date(firstSectionDateTime.getTime() + timeIndex  * 60 * 60 * 1000)
    firstSectionDateTime.setTime(updatedFirstSectionDateTime.getTime())
  }

  const userTz = getLocalUTCTimezone();
  const nextSectionDate = getNextSectionDate(userTz, timeIndex);
  
  const sectionNumber = currCourseWeek - 1

  useEffect(() => {
    if (!sectionData) {return}
    
    if (firstSectionTimestamp) {
      if(sectionData.timeIndex == "none") {
        setSectionMalformed(true)
        return
      }
    }
  }, [firstSectionTimestamp, sectionData]);

  // if they are a section leader (mentor) they don't have a section
  if(timeIndex === undefined || timeIndex === "none" || !firstSectionTimestamp || currCourseWeek == 0) {
    return <div>Error loading this section</div>
  }

  if (sectionMalformed) {

    return <div>Error loading this section. </div>
  }

  if (sectionDataError) {

    return <div>Error loading this section {sectionDataError.message}</div>;
  }

  if (sectionDataIsLoading || !sectionData || !firstSectionDateTime) {
    return <PartialLoading />;
  }

  const sectionCourse = sectionData.courseId;
  if(sectionCourse != courseId) {
    return <div>Error loading this section</div>;
  }

  return <SectionLoadingSectionLeader 
  sectionData={sectionData} 
  firstSectionDateTime={firstSectionDateTime}
  nextSectionDate={nextSectionDate}
  sectionNumber={sectionNumber}
  firstSectionTimestamp={firstSectionTimestamp}
  />;
};

const SectionLoadingSectionLeader = ({ sectionData, firstSectionDateTime, nextSectionDate, sectionNumber, firstSectionTimestamp }) => {
  const userId = useUserId();
  const courseId = useCourseId();
  const sectionId = useParams().sectionId;
  const sectionLeader = sectionData.sectionLeader;
  const db = firebase.firestore();
  const sectionLeaderRef = getUserDocumentRef(sectionLeader);
  const attedancePath = `users/${userId}/${courseId}/sectionAttendance`
  const attendanceRef = doc(db, attedancePath);
  const [sectionLeaderData, isLoading, dbUserError] =
    useDocumentDataOnce(sectionLeaderRef);

  const [sectionAttendance, sectionAttendanceLoading] = useDocumentData(attendanceRef);

  if (isLoading) {
    return <></>;
  }
  const sectionAttendanceOneIndex = {}
  if (sectionAttendance) {
    for(const [key, value] of Object.entries(sectionAttendance)) {
      sectionAttendanceOneIndex[parseInt(key) + 1] = value
    }
  }
  return (
    <SectionPageView
      sectionData={sectionData}
      sectionLeaderData={sectionLeaderData}
      sectionAttendance={sectionAttendanceOneIndex}
      firstSectionDateTime={firstSectionDateTime}
      nextSectionDate={nextSectionDate}
      sectionNumber={sectionNumber}
      firstSectionTimestamp={firstSectionTimestamp}
    />
  );
};

const SectionPageView = ({
  sectionData,
  sectionLeaderData,
  firstSectionDateTime,
  nextSectionDate,
  sectionNumber,
  sectionAttendance,
  firstSectionTimestamp
}) => {
  const windowSize = useWindowSize();
  const isBroken = windowSize.width < 1000;
  const courseId = useCourseId();

  const [editingAnnouncement, setEditingAnnouncement] = useState(false);

  const userId = useUserId();
  const sectionId = useParams().sectionId;
  const isMySection = userId === sectionLeaderData.id;
  const { userData } = useContext(ProfileContext);
  const courseRole = userData.courseRole;
  const isSectionLeader = isMinimumRole(courseRole, Role.SECTION_LEADER);


  const isFoothill = courseId === "foothill-cs49" || courseId === "cs49-f24";

  let sectionSchedule = []

  if (isFoothill) {
    sectionSchedule = [
      {
        topic: "Karel Control Flow",
      },
      {
        topic: "Stepwise Refinement",
      },
      {
        topic: "Intro to Python",
      },
      {
        topic: "Expressions",
      },
      {
        topic: "Python Control Flow",
      },
      {
        topic: "Graphics",
      },
      {
        topic: "Functions",
      },
      {
        topic: "Animation",
      },
      {
        topic: "NO SECTION - Thanksgiving break!",
      },
      {
        topic: "Lists + Dictionaries",
      }
    ];
  } else {
    sectionSchedule = [
      {
        topic: "Welcome to Section",
      },
      {
        topic: "Art of Karel",
      },
      {
        topic: "Intro to Python",
      },
      {
        topic: "Python Control Flow",
      },
      {
        topic: "Graphics",
      },
      {
        topic: "Lists and Dicts",
      },
    ];
  }

  const canEdit = isMySection || isMinimumRole(courseRole, Role.SECTION_LEADER);
  return (
    <div
      className="h-100 d-flex flex-row justify-content-center"
      style={{ flexGrow: 2 }}
    >
      <div style={{ maxWidth: 950, paddingRight: "0px", flexGrow: 1 }}>
        <Banner section={sectionData} height={200} />
        <SectionInfoRow
          
          section={sectionData}
          sectionLeaderData={sectionLeaderData}
          nextSectionDate={nextSectionDate}
          sectionNumber={sectionNumber}
        />

        <div style={{ padding: isBroken ? 10 : 0 }}>
          <div className="mt-3 splashCardRightTitle">
            Announcements
            {canEdit && (
              <button
                onClick={() => setEditingAnnouncement(!editingAnnouncement)}
                className="btn btn-light"
              >
                <FaEdit />
              </button>
            )}
          </div>

          <div
            className="card mt-2"
            style={{ backgroundColor: "#eff1fc", border: "none" }}
          >
            <div
              className="card-body"
              style={{ padding: editingAnnouncement ? 0 : 20 }}
            >
              <TipTap
                editable={editingAnnouncement}
                firebaseDocPath={`sections/${sectionId}/docs/announcement`}
                collaborative={editingAnnouncement}
                buttonBar={DiscussionButtonBar}
                showLoadingSkeleton={true}
              />
            </div>
          </div>
          <div className="mt-4 splashCardRightTitle">Section Schedule</div>
          <div className="table-responsive">
            <table className="table ">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Date</td>
                  <td>Topic</td>
                  {!isBroken && <td>Handout</td>}
                  {!isBroken && <td>Code</td>}
                  {!isBroken && isSectionLeader && <td>Lesson Plan</td>}

                  {!isBroken && <td>Solutions</td>}
                  {isBroken && <td>Docs</td>}
                  <td>{isBroken ? "Atten." : "Attendance"}</td>
                </tr>
              </thead>
              <tbody>
                {sectionSchedule.map((item, index) => {
                  return (
                    <SectionRow
                      isBroken={isBroken}
                      section={sectionData}
                      key={index}
                      weekIndex={index}
                      item={item}
                      sectionAttendance={sectionAttendance}
                      courseRole={courseRole}
                      firstDay={firstSectionDateTime}
                      firstSectionTimestamp={firstSectionTimestamp}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>

          <SectionRoster sectionData={sectionData} />
          <div style={{ height: 20 }} />
          <hr />
        </div>

        <div style={{ height: 20 }} />
      </div>
    </div>
  );
};

const SectionRoster = ({ sectionData }) => {
  let membersList = sectionData?.members ?? [];

  // remove any duplicates
  membersList = Array.from(new Set(membersList));

  return (
    <>
      <div className="mt-4 splashCardRightTitle">Who is in this section</div>
      <div className="d-flex flex-row flex-wrap justify-content-start">
        {membersList.map((memberId, index) => {
          return <SectionMember memberId={memberId} key={memberId} />;
        })}
      </div>
    </>
  );
};

function isFiveDaysBeforeWeeklyFirstSection(firstSectionTimestamp, weekIndex, currentDate) {

  const nDays = 14
  // this function replaces isAtLeastFridayBefore, which was based on a hardcoded section start date
  // return whether or not the currentDate is within 5 days of the first day of sections for the week
  // get five days before the course's section start date
  const firstSectionDate = new Date(firstSectionTimestamp);
  const fiveDaysBefore = new Date(firstSectionDate.getTime() - nDays * 24 * 60 * 60 * 1000);

  // shift to the correct week
  const daysInWeek = 7;
  const daysToAdd = weekIndex * daysInWeek;
  fiveDaysBefore.setDate(fiveDaysBefore.getDate() + daysToAdd);
  return currentDate >= fiveDaysBefore;
}

const isAtLeast24HoursBefore = (sectionDate, currentDate) => {
  const dayBeforeSection = new Date(
    sectionDate.getTime() - 24 * 60 * 60 * 1000
  );
  return currentDate >= dayBeforeSection;
};

const SectionRow = ({
  item,
  isBroken,
  weekIndex,
  section,
  sectionAttendance,
  courseRole,
  firstDay,
  firstSectionTimestamp
}) => {
  const sectionNum = weekIndex + 1;
  const attendance = sectionAttendance[sectionNum];
  const userTz = getLocalUTCTimezone();
  const courseId = section.courseId;

  const sectionDate = new Date(
    firstDay.getTime() + weekIndex * 7 * 24 * 60 * 60 * 1000
  );

  //just get the hours so the part after thecomma

  const sectionTimeStr = getSectionTimeStr(userTz, section.timeIndex);
  const sectionHour = sectionTimeStr.split(",")[1].trim();

  const currentDate = new Date();
  const solutionShowDate = new Date(sectionDate.getTime() + 60 * 60 * 1000); // One hour after sections for students

  const canSLSeeMaterials =
    isMinimumRole(courseRole, Role.SECTION_LEADER) &&
    isFiveDaysBeforeWeeklyFirstSection(firstSectionTimestamp, weekIndex, currentDate)
  const canStudentSeeHandoutCode =
    isMinimumRole(courseRole, Role.STUDENT) &&
    isAtLeast24HoursBefore(sectionDate, currentDate);
  const solutionAvailable = currentDate >= solutionShowDate; // One hour after sections for students

  const showHandout =
    canSLSeeMaterials ||
    canStudentSeeHandoutCode ||
    isMinimumRole(courseRole, Role.TA);
  const showCode =
    canSLSeeMaterials ||
    canStudentSeeHandoutCode ||
    isMinimumRole(courseRole, Role.TA);
  const showLessonPlan =
    canSLSeeMaterials || isMinimumRole(courseRole, Role.TA);
  const showSolution =
    canSLSeeMaterials ||
    (isMinimumRole(courseRole, Role.STUDENT) && solutionAvailable) ||
    isMinimumRole(courseRole, Role.TA);
  
    // const isTeacher = isMinimumRole(courseRole, Role.SECTION_LEADER);
    const isTeacher = isMinimumRole(courseRole, Role.MENTOR);

  const handoutPath = `/${courseId}/section/${sectionNum}/handout`;
  const lessonPlanPath = `/${courseId}/section/${sectionNum}/lessonplan`;
  const solutionsPath = `/${courseId}/section/${sectionNum}/solution`;

  // We store the path to the ide in firebase in format: ide/a/assnId
  const sectionIDEEnding = useSectionWeekIdePath(courseId, sectionNum);
  const sectionIDEPath = sectionIDEEnding
    ? `/${courseId}/${sectionIDEEnding}`
    : null;
  const dateWithSuffix = formateDateWithSuffix(sectionDate);
  return (
    <tr>
      <td>{sectionNum}</td>
      <td>
        <KindDate date={sectionDate} />
      </td>
      <td>{item.topic}</td>
      {!isBroken && (
        <>
          <td>
            {showHandout && (
              <Link to={handoutPath} >
                <FaFile />
              </Link>
            )}
          </td>
          <td>
            {showCode && (
              // this needs to know where I came from!
              <Link to={sectionIDEPath} state={{ from: `/${courseId}/section` }} >
                <FaCode />
              </Link>
            )}
          </td>
          {isTeacher && showLessonPlan && (
            <td>
              <Link to={lessonPlanPath} >
                <FaFile />
              </Link>
            </td>
          )}
          {isTeacher && !showLessonPlan && <td></td>}
          <td>
            {showSolution && (
              <Link to={solutionsPath}>
                <FaClipboardCheck />
              </Link>
            )}
          </td>
        </>
      )}
      {isBroken && (
        <td>
          {showHandout && (
            <Link to={handoutPath} >
              <FaFile />
            </Link>
          )}
          {showCode && (
            <Link to={sectionIDEPath} >
              <FaCode />
            </Link>
          )}
          {showLessonPlan && (
            <Link to={lessonPlanPath} >
              <FaFile />
            </Link>
          )}
          {showSolution && (
            <Link to={solutionsPath}>
              <FaClipboardCheck />
            </Link>
          )}
        </td>
      )}
      <td>
        <Attendance attendance={attendance} isVisible={solutionAvailable} />
      </td>
    </tr>
  );
};

const Attendance = ({ attendance, isVisible }) => {
  if (attendance === true) {
    return <FaCheck style={{ color: "text-success" }} />;
  } else if (isVisible) {
    return <FaTimes style={{ color: "text-danger" }} />;
  } else {
    return null;
  }
};

const SectionInfoRow = ({ section, sectionLeaderData, nextSectionDate, sectionNumber }) => {
  const db = getFirestore(getApp());
  
  const windowSize = useWindowSize();
  const isBroken = windowSize.width < 1000;
  const courseId = useParams().courseId;
  const sectionId = useParams().sectionId;
  const navigate = useNavigate();
  const navigateToSectionForum = () => {
    const targetUrl = `/${courseId}/sectionforum/${sectionId}`;
    navigate(targetUrl);
  };
  const navigateToSectionEmail = () => {
    const targetUrl = `/${courseId}/section/${sectionId}/email`;
    navigate(targetUrl);
  };

  // getFirstDayOfSection
  const userId = useUserId();
  const timeIndex = section.timeIndex;
  const zoomLink = section?.zoomLink;
  const { userData } = useContext(ProfileContext);
  const userTz = getLocalUTCTimezone();
  const sectionTimeStr = getSectionTimeStr(userTz, timeIndex);
  const dateWithSuffix = formateDateWithSuffix(nextSectionDate);
  //convert nextSectionDate to a stringlike "May 12th"
  // const timeUntil = getTimeUntil(nextSectionDate)
  const serverTime = useServerTime(60000)
  const [timeUntil, setTimeUntil] = useState(getTimeUntil(nextSectionDate, new Date()));

  useEffect(() => {
    // recheck
    if(!nextSectionDate || !serverTime) return
    setTimeUntil(getTimeUntil(nextSectionDate,new Date(serverTime)));

  }, [serverTime, nextSectionDate]);

  const changeNameInDatabase = async (newName) => {
    const sectionRef = doc(db, "sections", sectionId);
    await updateDoc(sectionRef, {
      name: newName,
    });
  };

  const renameSection = () => {
    Swal.fire({
      title: "Rename Section",
      input: "text",
      inputValue: section.name,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
        // if its less than 3 characters after trimming
        if (value.trim().length < 3) {
          return "Section name must be at least 3 characters";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const newName = result.value;
        changeNameInDatabase(newName);
      }
    });
  };

  const courseRole = userData?.courseRole;
  const isMySection = userId === sectionLeaderData.id;
  const canEdit = isMySection || isMinimumRole(courseRole, Role.TA);

  const isFoothill = courseId === "foothill-cs49" || courseId === "cs49-f24";
  // mobile view
  if (isBroken) {
    return (
      <div className="container">
        <div className="row">
          <div className="col" style={{ textAlign: "center" }}>
            <div className="d-flex flex-row justify-content-center">
              <SectionLeaderImg canEdit={canEdit} sectionLeaderData={sectionLeaderData} />
            </div>
            <h1 style={sectionTitleStyle}>
              {section.name} Section{" "}
              {canEdit && (
                <button onClick={renameSection} className="btn btn-light">
                  <FaEdit />
                </button>
              )}
            </h1>
            <h2 style={sectionLeaderNameStyle}>
              Section Leader: <SectionLeaderName sectionLeaderData={sectionLeaderData}/>
            </h2>
            <h2 className="mt-3" style={sectionLeaderNameStyle}>
              <FaCalendar style={{ marginTop: -5 }} />{" "}
              <span style={timeInnerStyle}>{sectionTimeStr}</span>
            </h2>
            <h2 className="mt-2" style={sectionLeaderNameStyle}>
              <FaClock style={{ marginTop: -5 }} />{" "}
              <span style={timeInnerStyle}>
                Next section: {getNextSectionTimeStr(sectionNumber,dateWithSuffix, timeUntil)}
              </span>
            </h2>
            <div style={{ height: "20px" }} />
            <div className="d-flex flex-column align-items-center justify-content-center">
              <RSVP section={section} timeIndex={section.timeIndex} />
              {isFoothill ? <CustomizedZoomButton zoomLink={zoomLink} /> : <JoinSectionZoomButton
                sectionId={sectionId}
                nextSectionDate={nextSectionDate}
                timeIndex={section.timeIndex}
              />}
              <button
                onClick={() => navigateToSectionForum()}
                className="btn btn-primary  position-relative mt-2"
              >
                <FaCommentDots style={{ marginTop: -5 }} /> Section Forum
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // desktop view
  return (
    <div style={infoRowStyle}>
      <div className="d-flex flex-row">
        <SectionLeaderImg canEdit={canEdit} sectionLeaderData={sectionLeaderData} />
        {/* <img style={sectionLeaderProfile(photoUrl)} /> */}
        <div className="ml-2 mt-2">
          <h1 style={sectionTitleStyle}>
            {section.name} Section{" "}
            {canEdit && (
              <button onClick={renameSection} className="btn btn-light btn-sm">
                <FaEdit />
              </button>
            )}
          </h1>
          <h2 style={sectionLeaderNameStyle}>
            Section Leader: <SectionLeaderName sectionLeaderData={sectionLeaderData}/>
          </h2>
          <h2 className="mt-3" style={sectionLeaderNameStyle}>
            <FaCalendar style={{ marginTop: -5 }} />{" "}
            <span style={timeInnerStyle}>{sectionTimeStr}</span>
          </h2>
          <h2 className="mt-2" style={sectionLeaderNameStyle}>
            <FaClock style={{ marginTop: -5 }} />{" "}
            <span style={timeInnerStyle}>
              Next section: {getNextSectionTimeStr(sectionNumber,dateWithSuffix, timeUntil)}
            </span>
          </h2>
        </div>
      </div>
      <div className="mt-2 d-flex flex-column">
        <RSVP section={section} timeIndex={section.timeIndex} />
        {isFoothill ? <CustomizedZoomButton zoomLink={zoomLink} /> : <JoinSectionZoomButton
          sectionId={sectionId}
          nextSectionDate={nextSectionDate}
          timeIndex={section.timeIndex}
        />}
        <button
          onClick={navigateToSectionForum}
          className="btn btn-primary  position-relative mt-2"
        >
          <FaCommentDots style={{ marginTop: -5 }} /> Section Forum
          {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            <FaBell />
            <span className="visually-hidden">unread messages</span>
          </span> */}
        </button>
        {Gate.hasSectionLeaderRole(userData) && (
          <button
            onClick={navigateToSectionEmail}
            className="btn btn-primary position-relative mt-2"
          >
            <FaEnvelope style={{ marginTop: -5 }} /> Email Your Section
          </button>
        )}
        <JoinAlternativeSectionButton 
          nextSectionDate={nextSectionDate} 
          sectionData={section}/>
      </div>
    </div>
  );
};

const SectionLeaderName = ({sectionLeaderData}) => {
  const courseId = useCourseId()
  return <Link to={`/${courseId}/user/${sectionLeaderData.id}`}>{sectionLeaderData?.displayName}</Link>
}



export const SectionLeaderImg = ({canEdit, sectionLeaderData}) => {
  const navigate = useNavigate();
  const courseId = useCourseId()
  const photoUrl = sectionLeaderData?.photoURL;
  const displayName = sectionLeaderData?.displayName;

  const onEmptyProfileClick = () => {
    if (canEdit) {
      navigate(`/${courseId}/profile`);
    }
  }

  const emptyStyle = {...slSalmonBadgeStyle}
  if(canEdit){
    emptyStyle.cursor = 'pointer'
  }

  if (photoUrl) {
    return <img aria-label="Profile picture" className="fullSizeProfile" style={slImgStyle} src={photoUrl}></img>
  } else {
    return <div onClick={() => onEmptyProfileClick()} aria-label="Profile picture" className="fullSizeProfile" style={emptyStyle}>
      <span style={{ fontSize: '2rem' }}>{displayName ? displayName[0] : "Unknown Teacher"}</span>
    </div>
  }
}


const getNextSectionTimeStr = (sectionNumber, dateWithSuffix, timeUntil) => {
  if(sectionNumber >= 7) {
    return "All done!"
  }
  return `${dateWithSuffix} (${timeUntil})`
}

const infoRowStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  paddingLeft: "15px",
};

const sectionTitleStyle = {
  fontSize: "32px",
  lineHeight: "38px",
  fontWeight: 700,
};

const timeInnerStyle = {};

const sectionLeaderNameStyle = {
  fontSize: "20px",
  lineHeight: "23px",
  fontWeight: 400,
};

const timeHeaderStyle = {
  textTransform: "uppercase",
  fontSize: "16px",
  // spacing to 1
  letterSpacing: "1px",
  fontWeight: 400,
};

const slImgStyle = {
  width: 195,
  height: 195,
  backgroundPosition: "50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  borderRadius: "25%",
  border: "solid 5px white",
  marginTop: "-40px",
  objectFit: "cover",
  zIndex: 1,
};

const slSalmonBadgeStyle = {
  ...slImgStyle,
  backgroundColor: 'salmon',
  color: 'white', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center'
}

function getIndividualSectionAttendance(attendanceData, userId) {
  if (!attendanceData) return {};
  const sectionAttendance = {};
  for (const weekIndex in attendanceData) {
    const weekAttendance = attendanceData[weekIndex];
    if (userId in weekAttendance) {
      const translatedWeek = parseInt(weekIndex) + 1;
      sectionAttendance[translatedWeek] = true;
    }
  }
  return sectionAttendance;
}

/**
 * TimelineCard
 */
const TimelineCardDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimelineCardButton = styled.button`
  padding: 8px 20px;
  border-radius: 10px;
  border: solid 1px;
  color: #373737;
  font-weight: 700;

  &:hover {
    color: white;
  }
`;

const TimelineCountdownCardHeader = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
`;
const TimelineCountdownCardButton = styled(TimelineCardButton)`
  background-color: #ffaf54;
  border-color: #ffbc80;
`;
