import Swal from "sweetalert2";
import { runUnitTests } from "./runUnitTests";
import { UnitTestResults, allTestsPassed, firstTestHasError, getFirstTestFailed } from "./UnitTestResults";
import { checkIsProjectConsole, checkIsProjectGraphics, checkIsProjectKarel, isCreativeProject, checkIsProjectRandom } from "ide/utils/general";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { autograderRunToast, logAiAutograderUserFeedback, notCorrectSwal, studentDisagreeSwal } from "./gptAutograder/gptAutograder";

/* 
 * function: runUnitTestsAndReportResults
 * --------------------------------------
 * This is the main entry point for anyone running unit tests. This function
 * combines running the tests and reporting the results, both to the user and
 * to the server. It is async so that when using AI autograders, we can keep
 * the UI responsive.
 * 
 * This function is only called in two places: From the IDE and from the TestView
 */

interface UnitTestParams {
  ideContext,
  navigate,
  userId,
  courseId,
  onwardsUrl,
  silent?: boolean
}

export async function runUnitTestsAndReportResults(unitTestParams: UnitTestParams) {
  const { ideContext } = unitTestParams;
  // if the project is a creative project, don't run any tests
  console.log('ideContext.assnData?.metaData?.isCreative', ideContext.projectData)
  if (isCreativeProject(ideContext.projectData)) {
    return;
  }

  if (hasAiUnitTests(ideContext) || hasRandomnessUnitTests(ideContext)) {
    autograderRunToast.fire()
    ideContext.setAiAutograderRunning(true)
    // If using AI autograders, don't block execution
    runUnitTests(ideContext).then(results => {
      ideContext.setAiAutograderRunning(false)
      // Set unittestresults here and then in style feedback can check if they have all passed
      ideContext.setUnittestResults(results);
      reportUnitTestResults(results,unitTestParams);
    });
  } else {
    // If using non-AI graders, wait for the tests to complete
    const unitTestResults = await runUnitTests(ideContext);
    // Set unittestresults here and then in style feedback can check if they have all passed
    ideContext.setUnittestResults(unitTestResults);
    if(unitTestResults.testsRun){
      reportUnitTestResults(unitTestResults,unitTestParams);
    }
    return unitTestResults;
  }
}

// ! NEWWW
/**
 * function: hasRandomenessUnitTests
 * ---------------------------------
 * Check if the assignment uses new randomness tests.
 */
export function hasRandomnessUnitTests(ideContext) {
  console.log("Checking if we have random tests!");
  
  const isRandom = ideContext.assnData?.metaData?.isRandom;
  return isRandom;
}

/**
 * function: hasAiUnitTests
 * -------------------------
 * Check if this assignment has any AI unit tests.
 */
export function hasAiUnitTests(ideContext) {
  const assnData = ideContext.assnData
  const projectData = ideContext.projectData
  const isGraphics = checkIsProjectGraphics(projectData, assnData);
  const isRandom = assnData?.metaData?.isRandom

  // only graphics and random can have AI unit tests
  if (isGraphics || isRandom) {
    return true
    // const hiddenExamples = assnData?.hiddenExamples?.hiddenExamples
    // return hiddenExamples && hiddenExamples.length > 0
  }

  return false
}


export function hasHiddenExamples(ideContext) {
  return !!(ideContext.assnData?.hiddenExamples?.hiddenExamples)
}

/*
 * function: markAssnCorrect
 * -------------------------
 * Mark the assignment as correct in the database.
 */
export const markAssnCorrect = async (userId, courseId, assnId) => {
  const db = getFirestore()
  const firebasePath = `/users/${userId}/${courseId}/assnProgress`
  const newCompletion = {}
  newCompletion[assnId] = true
  const completeDoc = doc(db, firebasePath)
  await setDoc(completeDoc, newCompletion, { merge: true })
}

/****************************************************************************
 *                      Helper Functions                                    *
 ****************************************************************************/

function reportUnitTestResults(
  results: UnitTestResults,
  unitTestParams: UnitTestParams) {
  const { ideContext, navigate, userId, courseId, onwardsUrl } = unitTestParams;

  const silent = unitTestParams.silent ?? false; // silently run unit tests when assignment is submitted

  updateTestCenterView(ideContext, results);
  const passedAll = allTestsPassed(results);
  const assnId = ideContext.assnData?.metaData.uid
  if (passedAll) {
    markAssnCorrect(userId, courseId, assnId).then(() => {
      if (!silent){
        reportPassedUnitTests(ideContext, navigate, onwardsUrl);
      }
    })
  } else if (shouldReportFailedTest(results) && !silent) {
    reportFailedUnitTests(
      results,
      ideContext,
      navigate,
      userId,
      courseId,
      assnId,
      onwardsUrl
    );
  }
}

function shouldReportFailedTest(results) {
  if (firstTestHasError(results)) {
    return false
  }
  return true;
}


function reportPassedUnitTests(ideContext, navigate, onwardsUrl) {
  if (ideContext.isTabletOrLarger) {
    showStyleFeedbackModal(ideContext)
  } else {
    Swal.fire({
      title: 'You did it!',
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: 'Onwards!',
      cancelButtonText: 'Keep Working',
      preConfirm: () => {
        navigate(onwardsUrl)
      }
    })
  }
}

function reportFailedUnitTests(
  results,
  ideContext,
  navigate,
  userId,
  courseId,
  assnId,
  onwardsUrl) {

  // if its an ai unit test (and not a randomness test), they can disagree
  if(hasAiUnitTests(ideContext)) {
    notCorrectSwal.fire().then((result) => {
			if (result.isDenied) {
				markAssnCorrect(userId, courseId, assnId)
        // record a disagreement
        logAiAutograderUserFeedback({results, userId, courseId, assnId})
				studentDisagreeSwal.fire({
					preConfirm: () => {
            
						navigate(onwardsUrl)
					}
				})
			}
		})
    return
  }

  const assnData = ideContext.assnData;
  const projectData = ideContext.projectData;
  const isKarel = checkIsProjectKarel(projectData, assnData);
  const isConsole = checkIsProjectConsole(projectData, assnData);
  const isRandom = checkIsProjectRandom(assnData);

  const firstTestFailed = getFirstTestFailed(results);
  const nTests = results.testResults.length;
  let text = "The program finished running, but didn't solve the problem.";
  if (isKarel) {
    if(nTests == 1) {
      text = `Karel finished running but didn't solve the problem. Either Karel is in the wrong place or the beepers are.`
    } else {
      text = `Karel finished running but didn't solve the problem.
      Check the world named "${firstTestFailed}".`
    }
  } else if (isConsole) {
    text = `The program finished running but the output wasn't what we expected. Check Test ${firstTestFailed}.`

    if(isRandom) {
      // Cam fail formatting tests, variable validation tests, or range tests
      if(firstTestFailed === "Random Range") {
        console.log("Failed random range test!");
        
        const incorrectRanges = results.testResults[0].rawResult.incorrectRanges.map(el => `(${el.min}, ${el.max})`).join(", ");
        text = `The program finished running, but it looks like the bounds on your random function calls are incorrect. Check your ranges ${incorrectRanges}`;
      } else if (firstTestFailed === "Random Formatting") {
        console.log("Failed formatting tests!")
        text = "The program finished running but the output wasn't what we expected. Make sure it matches the example exactly!";
      } else if(firstTestFailed === "Random Validation") {
        console.log("Failed variable validation");
        text = "The program finished running but the output wasn't what we expected. Make sure you're doing the right operations on your variables!";
      }
    }
  }

  Swal.fire({
    text: text,
    icon: 'info',
    confirmButtonText: 'keep it up!',
  })
}

function showStyleFeedbackModal(ideContext) {
  Swal.fire({
    title: 'You did it!',
    icon: 'success',
    showCancelButton: true,
    confirmButtonText: 'Check Out Style Feedback!',
    cancelButtonText: 'Keep Working'
  }).then((result) => {
    if (result.isConfirmed) {
      ideContext.setSelectedTab("Style Feedback")
      if (ideContext.leftColViewState === "minimized") {
        ideContext.setLeftColViewState("standard")
      }
    }
  })
}

function updateTestCenterView(ideContext, results) {
  const { setConsoleTestResults } = ideContext;
  const isConsole = checkIsProjectConsole(
    ideContext.project,
    ideContext.assnData
  );
  if (!isConsole) {
    return;
  }
  const testResults = results.testResults;
  const rawResults = []
  for (const result of testResults) {
    const rawResult = result.rawResult;
    rawResults.push({
      ...rawResult,
      state: result.isSuccess ? "success" : "failure"
    })
  }
  console.log('rawResults', rawResults)
  setConsoleTestResults(rawResults);
}
